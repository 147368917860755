
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ChatRichTextEditor from '@/components/views/ChatView/ChatRichTextEditor.vue';
import ChatMessageActions from '@/components/views/ChatView/ChatMessageActions.vue';
import AttachmentList from '@/components/views/ChatView/AttachmentList.vue';
import Attachment from '@/models/Attachment';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import SingleNextStepForm from '@/components/global/SingleNextStepForm.vue';
import moment from 'moment';
import NextStepTypeRepository from '@/repositories/NextStepTypeRepository';
import ReminderOptionRepository from '@/repositories/ReminderOptionRepository';
import UpgradedNextStep from '@/models/interfaces/UpgradedNextStep';
import { saveChatMessage } from '@/helpers/chat/ChatHelper';
import i18n from '@/i18n';
import { ActivityFormOptions } from '@/interfaces/components/chat/ActivityFormOptions';
import { UploadFile } from 'ant-design-vue/types/upload';
import { UserRepository } from '@/repositories/UserRepository';
import User from '@/models/User';

@Component({
    name: 'ChatInputArea',
    components: {
        ChatRichTextEditor,
        ChatMessageActions,
        AttachmentList,
        SingleNextStepForm,
    },
})
export default class ChatInputArea extends Vue {
    @Prop({ required: true }) private propChatId!: string | null;
    @Prop({ required: true }) private leadId!: string;
    @Prop({ required: true }) private selectedProjectId!: string;
    @Prop({ default: false }) private isProjectInRequestState!: boolean;

    private attachments: Attachment[] = [];
    private content = '';
    private isNextStepFormActive = false;
    private emptyNextStep = {
        nextStepType: null,
        reminderOption: null,
        assignedUser: null,
        date: null,
        name: '',
        note: '',
        completed: false,
        localKey: 0,
    };
    private firstSelectedUserId: string | null = null;
    private singleNextStep: UpgradedNextStep | null = null;
    private messageIsBeingCreated = false;

    private get allUsers() {
        return CMSUserRepository.getAll();
    }

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }

    private get nextStepTypes() {
        return NextStepTypeRepository.getAll();
    }

    private get reminderOptions() {
        return ReminderOptionRepository.getAll();
    }

    private selectUser(userId: string) {
        if (this.firstSelectedUserId != null) {
            return;
        }
        this.firstSelectedUserId = userId;
    }

    private addEmptyNextStep(activityFormOptions?: ActivityFormOptions) {
        const currentUserId = (this.currentUser as User).id;

        if (activityFormOptions) {
            this.singleNextStep = {
                ...this.emptyNextStep,
                date: moment(),
                note: activityFormOptions.content,
                assignedUser: activityFormOptions.selectedUserId ? activityFormOptions.selectedUserId : currentUserId,
                localKey: 1,
                messageId: activityFormOptions.messageId,
            };
            return;
        }

        this.singleNextStep = {
            ...this.emptyNextStep,
            date: moment(),
            note: this.content,
            assignedUser: this.firstSelectedUserId ? this.firstSelectedUserId : currentUserId,
            localKey: 1,
        };
    }

    private pushToAttachments(attachment: Attachment) {
        this.attachments.push(attachment);
    }

    private async onSaveMessage() {
        this.messageIsBeingCreated = true;

        try {
            await saveChatMessage({
                chatId: this.propChatId,
                selectedProjectId: this.selectedProjectId,
                content: this.content,
                attachments: this.attachments,
                singleNextStepFormReference: this.$refs.singleNextStep as SingleNextStepForm,
                shouldCreateNextStep: false,
            });
        } catch (e) {
            this.messageIsBeingCreated = false;
            this.$notification.error({
                message: i18n.t('Došlo je do greške') as string,
                description: (e as Error).message,
            });
            return;
        }

        this.messageIsBeingCreated = false;
        this.onCancel();
    }

    private async onCreateActivity() {
        this.messageIsBeingCreated = true;

        try {
            await saveChatMessage({
                chatId: this.propChatId,
                selectedProjectId: this.selectedProjectId,
                content: this.content,
                attachments: this.attachments,
                singleNextStepFormReference: this.$refs.singleNextStep as SingleNextStepForm,
                chatMessageId: this.singleNextStep != null ? this.singleNextStep.messageId : null,
                shouldCreateNextStep: true,
            });
        } catch (e) {
            this.messageIsBeingCreated = false;
            this.$notification.error({
                message: i18n.t('Došlo je do greške') as string,
                description: (e as Error).message,
            });
            return;
        }

        this.messageIsBeingCreated = false;
        this.onCancel();
    }

    private onCancel() {
        this.singleNextStep = null;
        this.isNextStepFormActive = false;
        this.firstSelectedUserId = null;
    }

    private showCreateActivityForm(activityFormOptions?: ActivityFormOptions) {
        if (this.currentUser == null) {
            return;
        }

        this.addEmptyNextStep(activityFormOptions);
        this.isNextStepFormActive = true;
    }

    private onRemoveFile(file: UploadFile) {
        const index = this.attachments.findIndex((attachment) => attachment.id === file.uid);
        this.attachments.splice(index, 1);
    }

    private clearAttachments() {
        this.attachments = [];
    }

    private resetForm() {
        this.onCancel();
    }

    private created() {
        EventBus.$on(EventBusEvents.showCreateActivityForm, this.showCreateActivityForm);
        EventBus.$on(EventBusEvents.clearContent, this.clearAttachments);
        EventBus.$on(EventBusEvents.resetChatInputForm, this.resetForm);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.showCreateActivityForm, this.showCreateActivityForm);
        EventBus.$off(EventBusEvents.clearContent, this.clearAttachments);
        EventBus.$off(EventBusEvents.resetChatInputForm, this.resetForm);
    }

    @Watch('selectedProjectId')
    private onSelectedProjectIdChange() {
        this.firstSelectedUserId = null;
        this.singleNextStep = null;
    }
}
