
import { Component, Prop, Vue } from 'vue-property-decorator';
import ChatKebabOptions from '@/components/views/ChatView/ChatKebabOptions.vue';
import i18n from '@/i18n';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

@Component({
    name: 'ChatMessageActions',
    components: {
        ChatKebabOptions,
    },
})
export default class ChatMessageActions extends Vue {
    @Prop({required: true}) private isNextStepFormActive!: boolean;
    @Prop({required: true}) private loading!: boolean;
    @Prop({default: false}) private isDisabled!: boolean;

    private chatActionList = [
        {
            label: i18n.t('Stvori aktivnost'),
            callback: () => {
                EventBus.$emit(EventBusEvents.showCreateActivityForm);
            },
            isVisible: true,
            isDisabled: false,
        },
    ];

    private onSave() {
        this.$emit('saveMessage');
    }

    private onCancel() {
        this.$emit('cancel');
    }

    private onCreateActivity() {
        this.$emit('createActivity');
    }
}
