export enum ProjectUnsavedDataComponents {
    PROJECT_INFO = 'projectInfo',
    EDITABLE_DROPDOWN_BLOCK = 'editableDropdownBlock',
    EDITABLE_CONTENT_BLOCK = 'editableContentBlock',
    NEXT_STEP_MODULE = 'nextStepModule',
    OFFER_HISTORY = 'offerHistory',
    DELIVERY_AND_ASSEMBLY_MODULE = 'deliveryAndAssemblyModule',
    TAX_RATE = 'taxRate',
    PROJECT_ATTACHMENTS = 'projectAttachments',
    ADDRESS_FORM = 'addressForm',
    PRODUCTS = 'products',
    CONTACT_PERSON = 'contactPerson',
    ORDER_NOTES = 'orderNotes',
    BANK_ACCOUNTS = 'bankAccounts',
}
