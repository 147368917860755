
import { Component, Vue } from 'vue-property-decorator';
import { Empty } from 'ant-design-vue';

@Component({
    name: 'NoMessages',
})
export default class NoMessages extends Vue {
    // @ts-ignore
    private simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
}
