
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import { ProjectShortInfo } from '@/interfaces/components/chat/ProjectShortInfo';
import { convertProjectsToProjectInfo } from '@/helpers/chat/ChatHelper';
import ChatContentHeader from '@/components/views/ChatView/ChatContentHeader.vue';
import NoMessages from '@/components/views/ChatView/NoMessages.vue';
import ChatMessageList from '@/components/views/ChatView/ChatMessageList.vue';
import ChatKebabOptions from '@/components/views/ChatView/ChatKebabOptions.vue';
import { UserRepository } from '@/repositories/UserRepository';
import ChatInputArea from '@/components/views/ChatView/ChatInputArea.vue';
import ChatRepository from '@/repositories/ChatRepository';

@Component({
    name: 'ChatContent',
    components: {
        ChatKebabOptions,
        ChatContentHeader,
        NoMessages,
        ChatMessageList,
        ChatInputArea,
    },
})
export default class ChatContent extends Vue {
    @Prop({required: true}) private project!: Project | null;
    @Prop({required: true}) private selectedProjectId!: null | string;
    @Prop({default: false}) private isInProjectView!: boolean;
    @Prop({default: false}) private isProjectInRequestState!: boolean;

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }

    private get projectInfo() {
        if (this.project === null) {
            return null;
        }
        return convertProjectsToProjectInfo(this.project) as ProjectShortInfo;
    }

    private get messageList() {
        if (this.project == null || this.project.chat == null) {
            return [];
        }
        return ChatRepository.getMessagedFromChatById(this.project.chat.id);
    }

    private get chatId() {
        if (this.project == null || this.project.chat == null) {
            return null;
        }

        return this.project.chat.id;
    }

    private get leadId() {
        if (this.project == null || this.project.projectLead == null) {
            return null;
        }

        return this.project.projectLead.id;
    }
}
