
import { Component, Prop, Vue } from 'vue-property-decorator';
import Tab from '@/components/views/ChatView/Tab.vue';
import SearchChat from '@/components/views/ChatView/SearchChat.vue';
import ProjectRepository from '@/repositories/ProjectRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { UserRepository } from '@/repositories/UserRepository';
import { TabKeys } from '@/enums/components/CmsIndex/TabKeys';
import { fetchChatProjects } from '@/helpers/chat/ChatHelper';
import Project from '@/models/Project';

@Component({
    name: 'Sidebar',
    components: {
        Tab,
        SearchChat,
    },
})
export default class Sidebar extends Vue {
    @Prop({ required: true }) private selectedProjectId!: null | string;

    private isSidebarLoading = true;
    private tabKeys = TabKeys;
    private activeTabKey = TabKeys.MyProjects;
    private hasUrlIdYieldedProject = false;

    private searchOptions = {
        page: 1,
        size: 10,
        query: '',
        total: 0,
    };

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }

    private get filteredTabItems() {
        return ProjectRepository.getChatProjects();
    }

    private searchChatProjects(query: string) {
        this.searchOptions.query = query;
        this.searchOptions.page = 1;

        this.fetchChatProjects();
    }

    private onChangeTab(key: TabKeys) {
        this.activeTabKey = key;
        this.searchOptions.page = 1;

        this.fetchChatProjects();
    }

    private onPaginationChange() {
        this.$nextTick(() => {
            this.fetchChatProjects();
        });
    }

    private onUpdatePagination({ totalNumber }: { totalNumber: number }) {
        if (totalNumber == null) {
            return;
        }

        this.searchOptions.total = totalNumber;
    }

    private async fetchChatProjects() {
        if (this.currentUser == null) {
            return Promise.reject();
        }
        this.isSidebarLoading = true;

        try {
            await this.fetchAndPreselectProjectFromUrlParams();
            await fetchChatProjects({
                activeTabKey: this.activeTabKey,
                userId: this.currentUser.id,
                searchOptions: this.searchOptions,
            });
        } catch (error) {
            this.onError((error as Error).message);
            return Promise.reject();
        }
        this.isSidebarLoading = false;
        return Promise.resolve();
    }

    private onError(message: string) {
        this.$notification.error({
            message: this.$t('Dogodila se greška') as string,
            description: message,
        });
        this.isSidebarLoading = false;
    }

    private emitDefaultProjectSelectionOrRemoveIdFromUrl() {
        if (!this.hasUrlIdYieldedProject) {
            EventBus.$emit(EventBusEvents.openChatProject, this.filteredTabItems[0].projectInfo.projectId);
        } else {
            this.$router.replace(`/chat`);
        }
    }

    private async fetchAndPreselectProjectFromUrlParams() {
        if (this.$route.params.id != null) {
            let response;
            try {
                response = await Project.getById(this.$route.params.id);
            } catch (e) {
                this.$notification.info({
                    message: this.$t('Projekt s tim ID-om ne postoji') as string,
                    description: ``,
                });
                return Promise.resolve();
            }

            if (response != null) {
                this.hasUrlIdYieldedProject = true;
                EventBus.$emit(EventBusEvents.openChatProject, this.$route.params.id);
                return Promise.resolve();
            }
        }

        return Promise.resolve();
    }

    private async mounted() {
        EventBus.$on(EventBusEvents.emitProjectPagination, this.onUpdatePagination);

        await this.fetchChatProjects();

        this.emitDefaultProjectSelectionOrRemoveIdFromUrl();
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.emitProjectPagination, this.onUpdatePagination);
    }
}
