
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TabItemInterface } from '@/interfaces/components/chat/TabItemInterface';
import TabItem from '@/components/views/ChatView/TabItem.vue';

@Component({
    name: 'Tab',
    components: {
        TabItem,
    },
})
export default class Tab extends Vue {
    @Prop({required: true}) private tabItems!: TabItemInterface[];
    @Prop({required: true}) private isLoading!: boolean;
    @Prop({required: true}) private selectedProjectId!: null | string;

    private get noSearchResultsReturned() {
        return !this.isLoading && this.tabItems.length <= 0;
    }
}
