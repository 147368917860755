
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProjectShortInfo } from '@/interfaces/components/chat/ProjectShortInfo';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import { getProjectsConnectionAndOfferIdString } from '@/helpers/chat/ChatHelper';

@Component({
    name: 'TabItem',
})
export default class TabItem extends Vue {
    @Prop({ required: true }) private projectInfo!: ProjectShortInfo;
    @Prop({ required: true }) private selectedProjectId!: string | null;

    private get bottomRowText() {
        return getProjectsConnectionAndOfferIdString(this.projectInfo.offerId, this.projectInfo.connection);
    }

    private onClick() {
        EventBus.$emit(EventBusEvents.openChatProject, this.projectInfo.projectId);
        EventBus.$emit(EventBusEvents.resetChatInputForm);
    }
}
