
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import NextStepType from '@/models/NextStepType';
import UpgradedNextStep from '@/models/interfaces/UpgradedNextStep';
import { getDatepickerLocale } from '@/helpers/LocaleHelper';
import ReminderOption from '@/models/ReminderOption';
import { ProjectUnsavedDataComponents } from '@/enums/components/Project/ProjectUnsavedDataComponents';
import { Action } from 'vuex-class';
import User from '@/models/User';
import AutocompleteGroupInput from '@/components/global/AutocompleteGroupInput.vue';
import NextStep from '@/models/NextStep';
import { transformObjectToBeBackendCompliant } from '@/helpers/NextStepHelper';
import ChatRichTextEditor from '@/components/views/ChatView/ChatRichTextEditor.vue';
import NextStepTypeRepository from '@/repositories/NextStepTypeRepository';
import { createNewChatMessageAndChat } from '@/helpers/chat/ChatHelper';
import CMSUserRepository from '@/repositories/CMSUserRepository';

@Component({
    name: 'SingleNextStepForm',
    components: { AutocompleteGroupInput, ChatRichTextEditor },
})
export default class SingleNextStepForm extends Vue {
    @Action('projectLoadingState/updateIsProjectLoading')
    private updateIsProjectLoading!: (newState: boolean) => void;
    @Prop({ required: true }) private isNextStepFormDisabled!: boolean;
    @Prop({ required: true }) private shouldDisplayOnlyOneItem!: boolean;
    @Prop({ default: true }) private shouldShowHeader!: boolean;
    @Prop({ default: false }) private isCompact!: boolean;
    @Prop({ required: true }) private leadId!: string;
    @Prop({ default: null }) private chatId!: string | null;
    @Prop({ default: null }) private projectId!: string | null;
    @Prop({ default: null }) private defaultNextStepTypeName!: string | null;
    @Prop({ required: true }) private nextStepIndex!: number;
    @Prop({ required: true }) private singleNextStepData!: UpgradedNextStep;
    @Prop({ required: true }) private nextStepTypes!: NextStepType[];
    @Prop({ required: true }) private reminderOptions!: ReminderOption[];
    @Prop({ required: true }) private users!: User[] | null;
    private isEditModeActive = false;
    private isFullHeight = false;
    private nextStepObject: UpgradedNextStep = {
        projectId: this.projectId,
    };
    private activeErrors: string[] = [];

    private get locale() {
        return getDatepickerLocale();
    }

    private get activeUsers() {
        return CMSUserRepository.getAllExceptArchivedUsers();
    }

    public async onSaveNextStep(shouldCreateChatMessage = true, chatMessageId?: string | null | undefined) {
        if (!this.isEditModeActive) {
            return Promise.reject();
        }
        this.updateIsProjectLoading(true);
        const chatMessageResponseId: string | null = await createNewChatMessageAndChat(
            shouldCreateChatMessage,
            this.projectId,
            this.chatId,
            this.nextStepObject.id
        );

        const backendCompliantObject = transformObjectToBeBackendCompliant(
            this.nextStepObject,
            chatMessageId || chatMessageResponseId
        );
        let nextStep;

        try {
            if (backendCompliantObject.id) {
                nextStep = await NextStep.updateExisting(backendCompliantObject.id, backendCompliantObject);
            } else {
                nextStep = await NextStep.createNew(backendCompliantObject);
            }
        } catch (error) {
            this.onError((error as Error).message);
            throw error;
        }

        this.showMessageAndDisableEditMode(this.$t('Aktivnost je uspješno spremljena') as string);

        EventBus.$emit(EventBusEvents.updateProject);

        return Promise.resolve(nextStep);
    }

    private async updateEditMode(newState: boolean) {
        this.isEditModeActive = newState;
        this.setIsFullHeight(newState);

        if (newState) {
            await this.fetchDropdownOptions();
        }

        if (!newState) {
            this.emitDataChanges(false);
        }
    }

    private onCancel() {
        this.updateEditMode(false);
        this.setDynamicPropertiesToNextStepObject();
    }

    private toggleIsFullHeight() {
        this.setIsFullHeight(!this.isFullHeight);
    }

    private setIsFullHeight(newState: boolean) {
        this.isFullHeight = newState;
    }

    private onDropdownChange(value: string, property: string) {
        if (this.nextStepObject[property] === undefined) {
            return;
        }

        this.nextStepObject[property] = value;
        this.onInput();
    }

    private onUpdateContent(content: string) {
        this.nextStepObject.note = content;
        this.onInput();
    }

    private onInput() {
        this.emitDataChanges(true);
    }

    private emitDataChanges(state: boolean) {
        EventBus.$emit(EventBusEvents.changesInDataMade, {
            component: ProjectUnsavedDataComponents.NEXT_STEP_MODULE,
            state,
        });
    }

    private onConfirmDeleteOpen() {
        this.$confirm({
            title: this.$t('Jeste li sigurni da želite obrisati ovu aktivnost?'),
            content: '',
            onOk: this.onDeleteNextStep,
        });
    }

    private async onDeleteNextStep() {
        this.$emit('onDeleteNextStep', this.nextStepObject.localKey);

        if (this.nextStepObject.id == null) {
            return;
        }

        try {
            await NextStep.deleteExisting(this.nextStepObject.id);
        } catch (error) {
            this.onError((error as Error).message);
            return;
        }

        this.showMessageAndDisableEditMode(this.$t('Aktivnost je uspješno obrisana') as string);
    }

    private onError(message: string) {
        this.$notification.error({
            message: this.$t('Dogodila se greška') as string,
            description: message,
        });
        this.updateIsProjectLoading(false);
    }

    private showMessageAndDisableEditMode(message: string) {
        this.$notification.success({
            message,
            description: ``,
        });
        this.updateEditMode(false);
    }

    private setDynamicPropertiesToNextStepObject() {
        Object.entries(this.singleNextStepData).forEach(([key, value]) => {
            this.$set(this.nextStepObject, key, value);
        });
    }

    private async onCompleteNextStep() {
        if (this.isEditModeActive || this.nextStepObject.id == null || this.nextStepObject.completed == null) {
            return;
        }

        try {
            await NextStep.updateCompletedState(this.nextStepObject.id, this.nextStepObject.completed);
        } catch (error) {
            this.onError((error as Error).message);
            return;
        }

        EventBus.$emit(EventBusEvents.onUpdateNextStepData);
        this.showMessageAndDisableEditMode(this.$t('Aktivnost je uspješno spremljena') as string);
    }

    private async fetchDropdownOptions() {
        try {
            await NextStepType.getAll();
            await ReminderOption.getAll();
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            return Promise.reject();
        }
        return Promise.resolve();
    }

    @Watch('singleNextStepData', { immediate: true, deep: true })
    private async onPropTextChange() {
        if (this.singleNextStepData == null) {
            return;
        }

        const nextStepEntityExists = this.singleNextStepData.id != null;

        this.setDynamicPropertiesToNextStepObject();
        await this.updateEditMode(!nextStepEntityExists);
        if (!nextStepEntityExists && this.defaultNextStepTypeName != null) {
            const defaultNextStepType = NextStepTypeRepository.getByName(this.defaultNextStepTypeName);
            this.nextStepObject.nextStepType = defaultNextStepType ? defaultNextStepType.id : null;
        }
    }
}
