
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Input } from 'ant-design-vue';

@Component({
    name: 'SearchChat',
    components: {
        Input,
    },
})
export default class SearchChat extends Vue {
    private input = '';

    @Watch('input')
    public onClearInput(newValue: null | string, oldValue: null | string) {
        if (newValue === '' && oldValue !== '') {
            this.onSearch();
        }
    }

    private onSearch() {
        this.$emit('onSearch', this.input);
    }
}
