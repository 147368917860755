
import { Component, Vue } from 'vue-property-decorator';
import Sidebar from '@/components/views/ChatView/Sidebar.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import ProjectRepository from '@/repositories/ProjectRepository';
import Project from '@/models/Project';
import ChatContent from '@/components/views/ChatView/ChatContent.vue';
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import store from '@/store';
import { UserRightsEnum } from '@/enums/global/UserRights';
import { RouteNames } from '@/enums/routes/RouteNames';

@Component({
    name: 'ChatView',
    components: {Sidebar, ChatContent},
})
export default class ChatView extends Vue {
    private selectedProject: null | Project = null;

    private get selectedProjectId() {
        if (this.selectedProject == null) {
            return null;
        }

        return this.selectedProject.projectId;
    }

    private selectProject(projectId: string) {
        this.selectedProject = ProjectRepository.getProjectById(projectId);
    }

    private beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
        const userRights = store.getters['jwtData/userRights'];
        const canSeeChat = userRights.includes(UserRightsEnum.VIEW_CHAT);

        if (canSeeChat) {
            next();
        } else {
            next({
                name: RouteNames.home,
            });
        }
    }

    private async created() {
        EventBus.$on(EventBusEvents.openChatProject, this.selectProject);
        EventBus.$on(EventBusEvents.updateProject, this.selectProject);

        if (this.$route.params.id != null) {
            const response = await Project.getById(this.$route.params.id);
            if (response != null) {
                this.selectProject(this.$route.params.id);
            } else {
                this.$router.replace(`/chat`);
            }
        }
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.openChatProject, this.selectProject);
        EventBus.$off(EventBusEvents.updateProject, this.selectProject);
    }
}
