
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getProjectsConnectionAndOfferIdString } from '@/helpers/chat/ChatHelper';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ProjectShortInfo } from '@/interfaces/components/chat/ProjectShortInfo';
import { Mutation } from 'vuex-class';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import HelpPage from '@/components/global/HelpPage.vue';

@Component({
    name: 'ChatContentHeader',
    components: { HelpPage },
})
export default class ChatContentHeader extends Vue {
    @Mutation('tableData/clearProjectNavigationSettings')
    private clearProjectNavigationSettings!: () => void;

    @Prop({ required: true }) private projectInfo!: ProjectShortInfo | null;
    @Prop({ default: false }) private isInProjectView!: boolean;

    private get bottomRowText() {
        if (this.projectInfo == null) {
            return '';
        }
        return getProjectsConnectionAndOfferIdString(this.projectInfo.offerId, this.projectInfo.connection);
    }

    private openProject() {
        if (this.projectInfo == null) {
            return;
        }

        this.clearProjectNavigationSettings();

        this.$router.push({
            name: RouteNames.project,
            params: {
                id: this.projectInfo.projectId,
            },
            query: {
                initialTab: `${ProjectTabValues.Basic}`,
            },
        });
    }

    private openChat() {
        if (this.projectInfo == null) {
            return;
        }

        this.$router.push({
            name: RouteNames.chat,
            params: {
                id: this.projectInfo.projectId,
            },
        });
    }
}
