import Chat from '@/models/Chat';

export default class ChatRepository {
    public static getAll() {
        return Chat.all();
    }

    public static getById(id: string) {
        return Chat.query().whereId(id).withAllRecursive().first();
    }

    public static getMessagedFromChatById(id: string) {
        const chatById = ChatRepository.getById(id);

        if (chatById == null) {
            return [];
        }

        return chatById.messages;
    }
}
